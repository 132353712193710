import classNames from 'classnames';

type SpeechIconProps = {
  className?: string;
};

export const SpeechIcon = ({ className }: SpeechIconProps) => (
  <div
    className={classNames(
      'relative flex aspect-square flex-shrink-0 items-center justify-center overflow-hidden rounded-full',
      className,
    )}
  >
    <style
      dangerouslySetInnerHTML={{
        __html: `
          @keyframes button-face-animation {
            0% { transform: translate(-50%, 0%); opacity: 0; }
            5% { transform: translate(0%, 0%); opacity: 1; }
            100% { transform: translate(0%, 0%); opacity: 1; }
          }
          @keyframes button-speech-animation {
            5% { transform: scale(0); opacity: 0; }
            15% { transform: scale(1); opacity: 1; }
            20% { transform: scale(0.9); }
            40% { transform: scale(1); opacity: 1; }
            100% { transform: scale(1); opacity: 1; }
          }
          `,
      }}
    />

    <svg className="relative -left-1 -top-0.5 h-full rounded-full" viewBox="0 0 49 44">
      <path
        d="M 13.924802,0.37165206 14,0.5581132 21.270046,19.134797 C 21.673189,20.16492 21.10216,21 20.008383,21 H 14 v 8 H 1.0006022 C 4.2042131,33.352321 14,34 14,34 v 8.000385 C 14,43.112567 13.101947,44 11.994141,44 H 6.4918145 C 0.72646772,40.324197 -2.6484276,37.044578 -3.7060577,30.144169 -8.1191595,1.3512958 12.864373,0.47490946 13.774399,0.39216491 Z"
        style={{
          animation: 'button-face-animation 5s forwards',
          animationDelay: '1s',
          transform: 'translate(-50%, 0%)',
          opacity: 0,
        }}
      />
      <path
        d="M25,20 C25,13.372583 30.372583,8 37,8 C43.627417,8 49,13.372583 49,20 C49,26.627417 43.627417,32 37,32 L20,32 L23,29 L22.9913642,29 C24.1007031,27.8954305 25,25.2154925 25,23.0092545 L25,20 Z M31,16 C31,16.5561352 31.4488226,17 32.0024733,17 L41.9975267,17 C42.544239,17 43,16.5522847 43,16 C43,15.4438648 42.5511774,15 41.9975267,15 L32.0024733,15 C31.455761,15 31,15.4477153 31,16 Z M31,20 C31,20.5561352 31.446616,21 31.9975446,21 L39.0024554,21 C39.5536886,21 40,20.5522847 40,20 C40,19.4438648 39.553384,19 39.0024554,19 L31.9975446,19 C31.4463114,19 31,19.4477153 31,20 Z M31,24 C31,24.5561352 31.4456547,25 31.9953976,25 L40.0046024,25 C40.5443356,25 41,24.5522847 41,24 C41,23.4438648 40.5543453,23 40.0046024,23 L31.9953976,23 C31.4556644,23 31,23.4477153 31,24 Z"
        style={{
          animation: 'button-speech-animation 5s forwards',
          animationDelay: '1s',
          transform: 'scale(0)',
          opacity: 0,
        }}
      />
    </svg>
  </div>
);
