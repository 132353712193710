'use client';

import { useState } from 'react';

import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { Heading } from '@/components/Fields/Heading';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { Translation } from '@/components/Translation';
import { Button, GhostButton } from '@/components/Fields/Buttons';
import { PolyglotIcon, TouchIcon } from '@/components/icons/icons';
import { smoothScrollToElementWithOffset } from '@/utils/scrollTo';
import { PricingButton } from '@/components/Pricing/PricingButton';
import { prices } from '@/constants/pricingData';

type FunnelType = 'individuals' | 'business' | 'polyglot';

interface FunnelCardProps {
  type: FunnelType;
  expanded: FunnelType | false;
  onExpand: (type: FunnelType) => void;
  title: string | JSX.Element;
  text: string;
  action: JSX.Element;
}

const FunnelCard = ({ type, expanded, onExpand, title, text, action }: FunnelCardProps) => (
  <div
    className={classNames(
      'relative flex-col gap-2 rounded bg-background-300/30 p-3 backdrop-blur-lg dark:bg-background-800/20 sm:flex sm:gap-6 sm:rounded-xl sm:p-6 md:w-1/3 lg:p-10 lt-md:text-sm',
      'lt-sm:overflow-clip lt-sm:bg-background-500/30 lt-sm:text-center lt-sm:transition-all lt-sm:dark:bg-background-800/50',
      {
        'lt-sm:max-h-14 lt-sm:[&>div]:hidden lt-sm:[&>span>p]:hidden': expanded !== type,
        'lt-sm:max-h-[400px]': expanded === type,
      },
    )}
  >
    <span onClick={() => onExpand(type)} className="flex flex-col gap-[inherit]">
      <Heading level={2} className="select-none lt-sm:mr-2 lt-sm:inline">
        {title}
        <ChevronDownIcon
          className={classNames('absolute right-3 top-5 w-5 opacity-50 transition-all sm:hidden', {
            'rotate-180 !opacity-100': expanded === type,
          })}
        />
      </Heading>
      <p className="text-balance font-light leading-6 opacity-70 sm:leading-7 lt-sm:inline">
        <Translation name={`home.${type}.text`}>{text}</Translation>
      </p>
    </span>
    <div className="mt-auto flex flex-row gap-3 text-white md:flex-col lg:flex-row lt-sm:pt-3">{action}</div>
  </div>
);

export const Funnels = () => {
  const [expanded, setExpanded] = useState<FunnelType | false>(false);

  const handleExpand = (type: FunnelType) => {
    setExpanded(expanded !== type ? type : false);
  };

  const funnelData: Array<Omit<FunnelCardProps, 'expanded' | 'onExpand'>> = [
    {
      type: 'individuals',
      title: <Translation name="home.individuals.title">Individuals</Translation>,
      text: 'No technical knowledge required. Transcribe and translate audio, or start a live recording in the click of a button using the VocalStack Dashboard.',
      action: (
        <PricingButton plan={prices.hobby} location="table" className="w-full bg-black/20 hover:bg-black/30">
          <Translation name="home.button.get-started" nonDefaultLocaleText="Start Now">
            Get Started
          </Translation>
        </PricingButton>
      ),
    },
    {
      type: 'business',
      title: <Translation name="home.businesses.title">Businesses</Translation>,
      text: "Unlimited scalability, API access, custom SLA and more. Learn how to integrate VocalStack's SaaS transcription platform into your infrastructure.",
      action: (
        <Button className="w-full bg-primary-500 hover:bg-primary-600">
          <Translation name="home.businesses.button">Go to VocalStack Business</Translation>
        </Button>
      ),
    },
    {
      type: 'polyglot',
      title: (
        <>
          Polyglot <PolyglotIcon className="inline w-5 lg:w-7" />
        </>
      ),
      text: 'Ready for something new? Start a Polyglot session and transcribe a LiveStream to multiple languages in real-time.',
      action: (
        <GhostButton
          className="w-full bg-black/20 hover:bg-black/30"
          onClick={() => smoothScrollToElementWithOffset(document.getElementById('polyglot-example'), 80)}
        >
          <TouchIcon className="-my-2 h-6 w-6" /> <Translation name="home.button.demo">Interactive Demo</Translation>
        </GhostButton>
      ),
    },
  ];

  return (
    <MaxWidthContainer className="flex flex-col gap-4 md:flex-row lg:gap-6 xl:gap-10">
      {funnelData.map((funnel) => (
        <FunnelCard key={funnel.type} expanded={expanded} onExpand={handleExpand} {...funnel} />
      ))}
    </MaxWidthContainer>
  );
};
