import { MouseEventHandler } from 'react';

import classNames from 'classnames';

export type BrowserMockupProps = {
  children: React.ReactNode;
  className?: string;
  color?: string;
  onClose?: MouseEventHandler<HTMLSpanElement> | undefined;
  onMinimize?: MouseEventHandler<HTMLSpanElement> | undefined;
  onMaximize?: MouseEventHandler<HTMLSpanElement> | undefined;
};

export const BrowserMockup = ({ children, className, color, onClose, onMinimize, onMaximize }: BrowserMockupProps) => (
  <div className={classNames('mx-auto w-full shadow-xl', className)}>
    <div className="relative flex h-11 w-full items-center justify-start gap-1.5 overflow-clip rounded-t-lg bg-gray-300 px-3 dark:bg-gray-800">
      <div
        className="absolute inset-0 bg-sky-400/20 opacity-20 dark:opacity-50"
        style={{ backgroundColor: color }}
      ></div>
      <span
        className={classNames('relative z-10 h-3 w-3 rounded-full bg-red-500 dark:bg-red-400', {
          'cursor-pointer hover:bg-red-600 hover:dark:bg-red-500': onClose,
        })}
        onClick={onClose}
      ></span>
      <span
        className={classNames('relative z-10 h-3 w-3 rounded-full bg-yellow-500 dark:bg-yellow-400', {
          'cursor-pointer hover:bg-yellow-600 hover:dark:bg-yellow-500': onMinimize,
        })}
        onClick={onMinimize}
      ></span>
      <span
        className={classNames('relative z-10 h-3 w-3 rounded-full bg-green-500 dark:bg-green-400', {
          'cursor-pointer hover:bg-green-600 hover:dark:bg-green-500': onMaximize,
        })}
        onClick={onMaximize}
      ></span>
    </div>
    <div className="relative w-full border-t-0 bg-gray-100 p-4 dark:bg-gray-900 lg:p-10">
      <div
        className="absolute inset-0 bg-sky-400/20 opacity-10 dark:opacity-20"
        style={{ backgroundColor: color }}
      ></div>
      <span className="relative z-10">{children}</span>
    </div>
  </div>
);
