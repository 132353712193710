/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { Timeline } from '@vocalstack/js-sdk';
import classNames from 'classnames';

import { TranscriptionLanguages } from './TranscriptionLanguages';

import { useLanguages } from '@/context/LanguageContext';
import { AlternativeLanguageSelector } from '@/components/Navigation/SecondaryNavigation/LanguageSelector';
import { LocaleKey } from '@/constants/locales';
import { formatSeconds } from '@/utils/formatSeconds';
import { AutoScroll } from '@/components/widgets/AutoScrollToBottom/AutoScrollToBottom';

type TimelineWithPendingAndLatest = (Timeline[0] & {
  pending?: boolean;
  latest?: boolean;
})[];

type ChunksTimelineProps = {
  timeline: Timeline;
  variant?: 'preview';
  languagesLoading: LocaleKey[];
  autoScroll?: boolean;
  showLanguageSelector?: boolean;
  showLanguagesBar?: boolean;
  capTimeAt?: number;
};

export const ChunksTimeline = (props: ChunksTimelineProps) => {
  const { variant, languagesLoading, autoScroll, showLanguageSelector, showLanguagesBar, capTimeAt } = props;
  const { languages } = useLanguages();

  const timeline = props.timeline as unknown as TimelineWithPendingAndLatest;

  const _languages = variant === 'preview' ? ['Detected'] : languages;
  const _timeline = variant === 'preview' ? timeline.slice(-4) : timeline;

  if (!_timeline?.length) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-2 sm:gap-5">
      {showLanguageSelector && <AlternativeLanguageSelector className="ml-[45px]" />}
      {showLanguagesBar && (
        <TranscriptionLanguages
          timeline={timeline}
          languagesLoading={languagesLoading}
          readonly={!showLanguageSelector}
        />
      )}
      <AutoScroll className="flex flex-col gap-2" enabled={autoScroll}>
        {_timeline
          ?.filter(({ start }) => typeof capTimeAt === 'undefined' || Number(start) < capTimeAt)
          .map(({ text, start, translations, latest, pending }, index) => (
            <div
              key={index}
              className={classNames('flex items-center gap-2 transition-all', {
                'animation-iteration-count:1 animate-slide-up overflow-clip': latest,
              })}
            >
              <div
                className={classNames(
                  'w-[45px] whitespace-nowrap text-right text-xs font-light tabular-nums transition-all',
                  { 'opacity-40': !latest, 'opacity-80': latest },
                )}
              >
                {formatSeconds(Number(start))}
              </div>
              <div className="flex w-full">
                <div className="flex w-full gap-2 lt-sm:gap-1">
                  {_languages.map((lang) => (
                    <div
                      key={lang}
                      style={{ overflowWrap: 'anywhere' }}
                      className={classNames('flex flex-1 rounded-md', 'text-sm', 'px-4 py-2 lt-sm:px-2 lt-sm:py-1.5', {
                        'even-chunks-row-bg bg-secondary-100 dark:bg-secondary-800/60': index % 2,
                        'flex-grow items-center gap-2 opacity-50': pending,
                      })}
                    >
                      {lang === 'Detected' ? text : (translations as any)?.[lang]}
                      {pending && '...'}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </AutoScroll>
    </div>
  );
};
