import { SpeechIcon } from './SpeechIcon';
import { TypeAnimation } from './TypeAnimation';

const SEQUENCE = [
  'Simple speech to text transcription!',
  'Transcribe audio files',
  'Transcribe most languages and dialects',
  'Transcribe by web or API',
  'Sign up for free and get started in less than a minute!',
];

type SpeechAnimationProps = {
  sequence?: string[];
};

export const SpeechAnimation = ({ sequence }: SpeechAnimationProps) => {
  return (
    <div className="mx-auto flex items-center justify-center gap-2 print:text-black sm:gap-5">
      <SpeechIcon className="h-12 fill-sky-500 dark:fill-sky-400 print:hidden sm:h-20" />
      <TypeAnimation delay={1500} sequence={sequence ?? SEQUENCE} inline />
    </div>
  );
};
