import { useEffect, useState } from 'react';

import { ArrowDownIcon } from '@heroicons/react/24/outline';

import { useScrollPosition } from '@/hooks/useScrollPosition';

export type AutoScrollProps = {
  className: string;
  children: React.ReactNode[];
  enabled?: boolean;
};

export const AutoScroll = ({ className, children, enabled }: AutoScrollProps) => {
  const [isStuckToBottom, setIsStuckToBottom] = useState(false);
  const [userHasSeenLatest, setUserHasSeenLatest] = useState(false);

  const { y } = useScrollPosition();

  const scrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    setUserHasSeenLatest(true);
  };

  useEffect(() => {
    const atTheBottom = y + window.innerHeight + 100 >= document.body.scrollHeight;
    setIsStuckToBottom(atTheBottom);
    if (atTheBottom) setUserHasSeenLatest(true);
  }, [y]);

  useEffect(() => {
    if (isStuckToBottom) {
      window.scrollTo(0, document.body.scrollHeight);
      setUserHasSeenLatest(true);
    } else {
      setUserHasSeenLatest(false);
    }
  }, [children]);

  return (
    <div className={className}>
      {children}
      {enabled && !isStuckToBottom && !userHasSeenLatest && (
        <ArrowDownIcon
          className="fixed bottom-1 right-1 w-12 animate-bounce rounded-full bg-primary-100/70 p-2 backdrop-blur dark:bg-background-800/70"
          onMouseDown={scrollDown}
          onTouchStart={scrollDown}
        />
      )}
    </div>
  );
};
