import(/* webpackMode: "eager", webpackExports: ["Chatwoot"] */ "/vercel/path0/components/Chat/Chatwoot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PublicNavigation"] */ "/vercel/path0/components/Navigation/PublicNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExamplesClient"] */ "/vercel/path0/components/pages/home/Examples/ExamplesClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Highlights"] */ "/vercel/path0/components/pages/home/Highlights/Highlights.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PolyglotExample"] */ "/vercel/path0/components/pages/polyglot/PolyglotExample/PolyglotExample.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PricingButton"] */ "/vercel/path0/components/Pricing/PricingButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/vercel/path0/components/Pricing/PricingCards.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Translation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/vercel/path0/context/LanguageContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
