import { useState, useEffect, useCallback } from 'react';

import { debounce } from '@/utils/debounce';

interface ScrollPosition {
  x: number;
  y: number;
}

export function useScrollPosition(delay: number = 100): ScrollPosition {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({ x: 0, y: 0 });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(() => setScrollPosition({ x: window.scrollX, y: window.scrollY }), delay),
    [delay],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return scrollPosition;
}
