'use client';

import { useEffect, useRef, useState } from 'react';

import { TypeAnimation as TAnimation } from 'react-type-animation';
import classNames from 'classnames';

type TypeAnimationProps = {
  className?: string;
  sequence: (string | undefined | false)[];
  delay?: number;
  cursor?: boolean;
  speed?: number;
  preRenderFirstString?: boolean;
  inline?: boolean;
};

export const TypeAnimation = ({
  className,
  sequence,
  delay,
  cursor = false,
  speed = 50,
  preRenderFirstString = false,
  inline,
}: TypeAnimationProps) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const updateMaxWidth = () => setMaxWidth((prevMaxWidth) => Math.max(container.offsetWidth, prevMaxWidth));
    const observer = new MutationObserver(updateMaxWidth);
    observer.observe(container, { childList: true, subtree: true });
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequence]);

  return (
    <div
      ref={containerRef}
      className={classNames('overflow-clip transition-all duration-700 empty:hidden dark:text-inherit', className)}
      style={{ minWidth: maxWidth }}
    >
      <TAnimation
        key={[...sequence, cursor, preRenderFirstString].join('-')}
        sequence={getSequence(sequence, delay)}
        wrapper="span"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        speed={speed as any}
        cursor={cursor}
        preRenderFirstString={preRenderFirstString}
        style={{ display: inline ? 'inline-block' : '' }}
        className="empty:hidden"
      />
    </div>
  );
};

const getSequence = (sequence: TypeAnimationProps['sequence'], delay?: number) => [
  delay ?? 0,
  ...insertNumbersBetweenStrings(sequence.filter((s) => s) as string[]),
];

const insertNumbersBetweenStrings = (array: string[], delay = 1000) => {
  return array.reduce((acc: (string | number)[], currentValue, index) => {
    acc.push(currentValue);
    if (index !== array.length - 1) {
      acc.push(delay);
    }
    return acc;
  }, []);
};
