import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';
import { LocaleKey, locales } from '@/constants/locales';

type LocaleCountryFlagProps = {
  localeKey: LocaleKey;
};

export const LocaleCountryFlag = ({ localeKey }: LocaleCountryFlagProps) => {
  const locale = locales.find(({ id }) => id === localeKey);

  return locale && <CountryFlag code={locale.country} className="h-3" aria-hidden="true" />;
};
