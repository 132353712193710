'use client';

import { useEffect } from 'react';

import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/20/solid';

import { betaCSS } from '../Navigation/Logo';

import { PricingButton } from './PricingButton';

import { useBilling, BillingCycle } from '@/context/BillingContext';
import { Prices, prices } from '@/constants/pricingData';
import { moneyValue } from '@/utils/money';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { ToggleComponent } from '@/components/Fields/Toggle';
import { Translation } from '@/components/Translation';
import { Heading } from '@/components/Fields/Heading';

export const PricingCards = () => {
  useEffect(() => {
    const confettiShown = localStorage.getItem('confettiShown');
    if (!confettiShown) {
      void import('js-confetti').then((module) => {
        const jsConfetti = new module.default();
        void jsConfetti.addConfetti({
          confettiColors: ['#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7'],
        });
        localStorage.setItem('confettiShown', 'true');
      });
    }
  }, []);

  return (
    <div className="flow-root overflow-x-clip lg:pb-0">
      <MaxWidthContainer>
        <div className="relative z-10">
          <h1
            className="mx-auto max-w-4xl text-center text-4xl font-bold tracking-tight sm:text-5xl"
            suppressHydrationWarning={true}
          >
            <Translation name="pricing.cards.header">Simple Pricing - Superior Quality</Translation>
          </h1>
          <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 opacity-60">
            <Translation name="pricing.cards.paragraph-1">
              Transcribe speech to text using the largest and most powerful AI models available, including:
            </Translation>{' '}
            <em>OpenAI Whisper large-v3 LLM</em>.{' '}
            <Translation name="pricing.cards.paragraph-2">
              Excellent handling of background noise, multiple accents, or live speech.
            </Translation>
          </p>
        </div>
        <div className="mt-20 flex flex-col items-center justify-center gap-2 text-pink-600 dark:text-pink-100 sm:-mb-20">
          <Heading
            level={4}
            className="border-b-[1px] border-b-pink-600/50 pb-2 text-center font-bold dark:border-b-pink-100/50 "
          >
            🎉 &nbsp;{' '}
            <Translation name="pricing.cards.header.welcome-title">Welcome to VocalStack Beta release!</Translation>
          </Heading>
          <p className="text-balance text-center font-light dark:font-extralight">
            <Translation name="pricing.cards.header.welcome-body">
              Be an early adopter and receive additional free transcription hours each month!
            </Translation>
          </p>
        </div>
        <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 sm:mt-32 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
          <svg
            viewBox="0 0 1208 1024"
            aria-hidden="true"
            className={classNames(
              'absolute h-[48rem] [mask-image:radial-gradient(closest-side,white,transparent)] lg:h-[64rem]',
              'opacity-50 xl:opacity-100',
              'xl:-top-48 xl:bottom-auto',
              'bottom-0 left-1/2 top-0 my-auto -translate-x-1/2',
              'hidden dark:block print:!hidden',
            )}
          >
            <ellipse cx={604} cy={512} rx={604} ry={512} fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" />
            <defs>
              <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>

          <div
            className="absolute inset-x-0 -top-32 z-30 -scale-y-100 scale-x-150 transform-gpu overflow-hidden blur-3xl dark:hidden print:!hidden"
            aria-hidden="true"
          >
            <div
              className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

          {Object.values(prices).map((plan) => (
            <Card
              includeBackground={false}
              key={plan.label}
              plan={plan}
              className={classNames({ 'lg:mt-4': !plan.featured })}
            />
          ))}
        </div>
      </MaxWidthContainer>
    </div>
  );
};

type CardProps = {
  plan: Prices[keyof Prices];
  className?: string;
  includeBackground?: boolean;
};

// TODO: infer index from plan

export const Card = ({ className, plan, includeBackground }: CardProps) => {
  const { billingCycle, setBillingCycle } = useBilling();
  const index = Object.values(prices).indexOf(plan);

  return (
    <>
      {includeBackground && (
        <>
          <svg
            viewBox="0 0 1208 1024"
            aria-hidden="true"
            className={classNames(
              'absolute -z-10 h-[44rem] [mask-image:radial-gradient(closest-side,white,transparent)]',
              'opacity-50',
              'bottom-0 left-1/2 -translate-x-1/2',
              'print:hidden! hidden dark:block',
            )}
          >
            <ellipse cx={604} cy={512} rx={604} ry={512} fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" />
            <defs>
              <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div
            className="absolute inset-x-0 top-20 -z-10 -scale-y-100 scale-x-150 transform-gpu overflow-hidden blur-3xl dark:hidden print:!hidden"
            aria-hidden="true"
          >
            <div
              className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </>
      )}
      <div
        className={classNames(
          'z-40 rounded-2xl lt-lg:!rounded-2xl',
          {
            'bg-white pt-4 shadow-xl ring-1 ring-gray-900/10 dark:bg-gray-100': plan.featured,
            'bg-primary-700/80 ring-1 ring-white/10 dark:bg-primary-800/80 lg:mb-14 lg:ring-0': !plan.featured,
            'sm:rounded-br-none sm:rounded-tr-none': index === 0,
            'sm:rounded-bl-none sm:rounded-tl-none': index === 2,
          },
          className,
        )}
      >
        <div className="m-8 lg:mt-8 xl:m-10 xl:mt-10">
          <h2
            className={classNames('text-center text-sm font-semibold leading-6', {
              'text-gray-900': plan.featured,
              'text-white': !plan.featured,
            })}
          >
            {plan.label}
          </h2>

          <div className="flex flex-col gap-6">
            <div className="mt-2 flex items-center justify-center gap-x-3">
              <div
                className={classNames('gap-x-3 text-4xl font-bold tracking-tight', {
                  'text-gray-900': plan.featured,
                  'text-white': !plan.featured,
                })}
              >
                {billingCycle === 'Annually' && plan?.monthly_price && plan?.yearly_price ? (
                  <>
                    <span
                      className={classNames(
                        'relative mr-2 font-extralight opacity-30',
                        'before:absolute before:inset-0 before:top-6 before:block before:border-t-2 before:border-t-gray-900',
                      )}
                      aria-hidden
                    >
                      {moneyValue(plan.monthly_price, 0)}
                    </span>
                    <span className="text-primary-500">{moneyValue(plan.yearly_price / 12, 0)}</span>
                  </>
                ) : plan.label === 'Premium' || plan?.monthly_price === 0 ? (
                  moneyValue(plan.monthly_price ?? 0, 0)
                ) : (
                  <span className="font-extralight opacity-50">
                    <Translation name="pricing.cards.custom-pricing">custom pricing</Translation>
                  </span>
                )}
              </div>
              <div className="text-sm leading-5">
                {plan?.label === 'Premium' && (
                  <>
                    <p className={classNames({ 'text-gray-900': plan.featured, 'text-white': !plan.featured })}>
                      <Translation name="pricing.cards.per-month">per month</Translation>
                    </p>
                    <p className="mt-[-3px] text-gray-500 opacity-80">
                      <Translation
                        name="pricing.cards.billed.monthly"
                        className={classNames({ hidden: billingCycle.toLowerCase() !== 'monthly' })}
                      >
                        Billed monthly
                      </Translation>
                      <Translation
                        name="pricing.cards.billed.annually"
                        className={classNames({ hidden: billingCycle.toLowerCase() !== 'annually' })}
                      >
                        Billed annually
                      </Translation>
                    </p>
                  </>
                )}
                {plan?.label === 'Hobby' && (
                  <p className="text-gray-300 dark:text-gray-400">
                    {plan?.monthly_price === 0 ? (
                      <Translation name="pricing.cards.no-recurring-costs">No recurring costs</Translation>
                    ) : (
                      <Translation name="pricing.cards.per-month">per month</Translation>
                    )}
                  </p>
                )}
              </div>
            </div>

            {plan.featured && (
              <div className="-mt-1 flex justify-center">
                <ToggleComponent
                  value1="Annually"
                  value2="Monthly"
                  activeValue={billingCycle}
                  theme="light"
                  onChange={(val) => setBillingCycle(val as BillingCycle)}
                />
              </div>
            )}

            <PricingButton plan={plan} location="cards" className="w-full" />
          </div>
          <div className="mt-8 flow-root sm:mt-10">
            <ul
              role="list"
              className={classNames('-my-2 divide-y border-t text-left text-sm leading-6 lg:border-t-0', {
                'divide-gray-900/5 border-gray-900/5 text-gray-600': plan.featured,
                'divide-white/5 border-white/5 text-white': !plan.featured,
              })}
            >
              {plan.highlights.map((mainFeature, index) => (
                <li key={mainFeature} className="text-balance flex gap-x-3 py-2">
                  <CheckIcon
                    className={classNames('h-6 w-5 flex-none', {
                      'text-primary-600': plan.featured,
                      'text-gray-200 dark:text-gray-400': !plan.featured,
                    })}
                    aria-hidden="true"
                  />
                  <Translation name={`pricing.cards.main-feature-${plan.label}-${index}`}>{mainFeature}</Translation>
                  {!index && plan.label !== 'Enterprise' && (
                    <div className={classNames(betaCSS, 'after:!static after:px-2 after:py-1')} />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
