import React, { useEffect, useState, useMemo } from 'react';

import classNames from 'classnames';

import { Spinner } from '@/components/widgets/spinners/Spinner';

type IframeProps = {
  id?: string;
  src: string;
  className?: string;
  onLeave?: () => void;
};

export const Iframe = ({ id, src, className, onLeave }: IframeProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => setLoading(true), [src]);

  const iframeElement = useMemo(
    () => (
      <iframe
        id={id}
        className={classNames(className, 'absolute inset-0 h-full w-full select-none', {
          'border-[1px] border-transparent': !loading, // forces re-render (required for mobile quirk)
        })}
        src={src}
        onLoad={() => setLoading(false)}
        onMouseLeave={onLeave}
        sandbox="allow-same-origin allow-scripts"
      ></iframe>
    ),
    [id, src, className, onLeave],
  );

  return (
    <>
      {loading && (
        <div className="justify center absolute inset-0 flex h-full w-full items-center text-center">
          <Spinner className="mx-auto h-28 w-28" />
        </div>
      )}
      {iframeElement}
    </>
  );
};
