import React, { useState, useEffect, useRef, useId } from 'react';

import { debounce } from '@/utils/debounce';

type ResponsiveScaleProps = {
  children: React.ReactNode;
  breakpoints: {
    [key: string]: {
      scale: number;
      fixMarginsX?: boolean;
      fixMarginsY?: boolean;
      defaultMargins?: { y: number; x: number };
    };
  };
};

export function ResponsiveScale({ breakpoints, children }: ResponsiveScaleProps) {
  const id = useId().replaceAll(':', '');
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [margin, setMargin] = useState<{ y: number; x: number }>({ y: 0, x: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const matchedBreakpoint = Object.keys(breakpoints).find((key) => window.matchMedia(key).matches);

      if (matchedBreakpoint !== currentBreakpoint) {
        if (matchedBreakpoint) {
          const { scale: newScale, fixMarginsX, fixMarginsY, defaultMargins } = breakpoints[matchedBreakpoint];
          setCurrentBreakpoint(matchedBreakpoint);
          setScale(newScale);

          if (containerRef.current) {
            const childNode = containerRef.current.children[0] as HTMLElement;
            if (childNode) {
              const { offsetWidth, offsetHeight } = childNode;
              const scaledWidthDifference = offsetWidth * (1 - newScale);
              const scaledHeightDifference = offsetHeight * (1 - newScale);
              setMargin({
                y: defaultMargins?.y || fixMarginsY ? -scaledHeightDifference / 2 : 0,
                x: defaultMargins?.x || fixMarginsX ? -scaledWidthDifference / 2 : 0,
              });
            }
          }
        } else {
          setCurrentBreakpoint(null);
          setScale(1);
          setMargin({ y: 0, x: 0 });
        }
      }
    };

    updateDimensions();
    const debounced = debounce(updateDimensions, 100);
    window.addEventListener('resize', debounced);

    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, [breakpoints, currentBreakpoint]);

  // Generate server-side styles for defaultMargins
  const serverSideStyles = Object.keys(breakpoints)
    .map((key) => {
      const { defaultMargins, scale } = breakpoints[key];
      if (defaultMargins) {
        return `
          @media ${key} {
            #${id} {
              transform: scale(${scale}) !important;
              margin-top: ${defaultMargins.y}px !important;
              margin-bottom: ${defaultMargins.y}px !important;
              margin-left: ${defaultMargins.x}px !important;
              margin-right: ${defaultMargins.x}px !important;
            }
          }
        `;
      }
      return '';
    })
    .join('');

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: serverSideStyles }} />

      <div
        id={id}
        ref={containerRef}
        className="origin-center transform-gpu transition-all"
        style={{
          transform: `scale(${scale})`,
          marginTop: `${margin.y}px`,
          marginBottom: `${margin.y}px`,
          marginLeft: `${margin.x}px`,
          marginRight: `${margin.x}px`,
        }}
      >
        {children}
      </div>
    </>
  );
}
