import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Timeline } from '@vocalstack/js-sdk';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';

import { LanguageSelector } from '@/components/Navigation/SecondaryNavigation/LanguageSelector';
import { Language, LocaleKey, locales } from '@/constants/locales';
import { useLanguages } from '@/context/LanguageContext';
import { LocaleCountryFlag } from '@/components/widgets/LocaleCountryFlag/LocaleCountryFlag';

type TranscriptionLanguagesProps = {
  timeline: Timeline;
  languagesLoading?: LocaleKey[];
  className?: string;
  readonly?: boolean;
};

const getUniqueLanguages = (timeline: Timeline, atLeast = 0.1): LocaleKey[] => {
  if (!timeline || timeline.length === 0) return [];
  const langs = timeline.map((chunk) => chunk.language as LocaleKey);
  const langCount: Record<LocaleKey, number> = langs.reduce(
    (acc, lang) => {
      if (lang) {
        acc[lang] = (acc[lang] || 0) + 1;
      }
      return acc;
    },
    {} as Record<LocaleKey, number>,
  );
  const threshold = timeline.length * atLeast;
  return Object.entries(langCount)
    .filter(([, count]) => count > threshold)
    .map(([lang]) => lang as LocaleKey);
};

export const TranscriptionLanguages = (props: TranscriptionLanguagesProps) => {
  const { className, timeline, languagesLoading, readonly } = props;
  const { languages, setLanguages } = useLanguages();
  const [currentLangs, setCurrentLangs] = useState<LocaleKey[]>();

  useEffect(() => {
    setCurrentLangs(getUniqueLanguages(timeline));
  }, [timeline, timeline?.length]);

  return (
    <div
      className={classNames(
        'language-bar',
        'relative z-50 flex h-14 w-full gap-2 py-2 lt-sm:gap-1',
        {
          ['sticky top-[3.25rem]']: languages?.length > 1,
        },
        className,
      )}
    >
      <div className="language-bar-bg absolute bottom-0 left-0 right-0 top-0 z-10 bg-gray-100 backdrop-blur-lg dark:bg-gray-900/80"></div>

      <div className="w-[45px]"></div>
      {languages.map((lang, index) => (
        <div key={`${lang}-${index}`} className="relative z-20 flex flex-1 justify-center">
          {lang === 'Detected' ? (
            // TOTO: fix where multiple langs can return teh same flag (e.g. Welsh and UK English)
            Boolean(currentLangs?.length) && (
              <div className="flex items-center">
                {currentLangs?.map((currentLang, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-1"
                    title={locales.find((l) => l.id === currentLang)?.title}
                  >
                    <LocaleCountryFlag localeKey={currentLang} />
                  </div>
                ))}
                <span className="ml-1 whitespace-nowrap normal-case opacity-50"> ⇢ Detected</span>
              </div>
            )
          ) : (
            <LanguageSelector
              localeKey={lang}
              useLanguageContext
              place="bottom"
              disabled={readonly || !index}
              loading={languagesLoading?.includes(lang as LocaleKey)}
            />
          )}
          {index > 0 && (
            <CloseIcon
              className="absolute bottom-0 right-3 top-0 my-auto h-4 w-4 cursor-pointer opacity-50 hover:opacity-100"
              onClick={() => setLanguages((langs: Language[]) => langs.filter((currentLang) => currentLang !== lang))}
            />
          )}
        </div>
      ))}
    </div>
  );
};
