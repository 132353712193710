import React, { useEffect, useState } from 'react';

import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import classNames from 'classnames';

type PolyglotSwiperProps = {
  children?: React.ReactNode[];
  className?: string;
  onSlideIndexChange?: (index: number) => void;
  defaultOpenIndex?: number;
  enabled?: boolean;
};

export const PolyglotSwiper = ({
  children,
  className,
  defaultOpenIndex,
  enabled,
  onSlideIndexChange,
}: PolyglotSwiperProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const index = (children?.length || 0) - 1;
    if (!index) return;
    swiper?.slideTo(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children?.length]);

  useEffect(() => {
    if (swiper && defaultOpenIndex) {
      swiper.slideTo(defaultOpenIndex, 0);
    }
    setInitialized(true);
  }, [swiper]);

  if (!enabled) {
    return children;
  }

  return (
    <Swiper
      onSwiper={setSwiper}
      onSlideChange={(swiper) => {
        onSlideIndexChange?.(swiper.activeIndex);
      }}
      effect={'coverflow'}
      grabCursor
      centeredSlides
      // observeParents
      // observeSlideChildren
      slidesPerView={3}
      pagination={{
        clickable: true,
        dynamicBullets: false,
      }}
      coverflowEffect={{
        rotate: 40,
        stretch: 0,
        depth: 450,
        modifier: 1,
        slideShadows: false,
      }}
      modules={[EffectCoverflow, Pagination]}
      className={classNames(
        'w-[1000px] !overflow-visible [&_.swiper-pagination-bullet]:!scale-100 [&_.swiper-pagination-bullet]:dark:!bg-white [&_.swiper-pagination]:!-bottom-10 [&_.swiper-pagination]:!scale-150',
        {
          '[perspective:1200px] [&_.swiper-wrapper]:[transform-style:preserve-3d]': !initialized,
        },
        className,
      )}
    >
      {children?.map((child, index) => (
        <SwiperSlide
          key={index}
          className={classNames(
            // [&:not(.swiper-slide-active)_.marvel-device]:before:[transform:rotateY(10deg)] // <-- breaks in safari
            '[&_.marvel-device]:before:transition-all',
            'my-auto', //
            {
              '[&:not(.swiper-slide-visible)]:opacity-0': initialized && index !== swiper?.activeIndex,
              '!w-auto': initialized && index === swiper?.activeIndex,
              '!transform-none': initialized && index === swiper?.activeIndex,
            },
          )}
          style={{
            width: '33.333%',
            transform:
              (!initialized && index === 0) || (!initialized && index === 2)
                ? `translate3d(0px, 0px, -450px) rotateX(0deg) rotateY(${index === 0 ? '40deg' : '-40deg'}) scale(1)`
                : undefined,
          }}
          onClick={() => swiper?.slideTo(index)}
        >
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
