import { RefObject, useRef } from 'react';

import AudioPlayer from 'react-h5-audio-player';

import './audio-player.scss';

type PlayerProps = {
  src: string;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onSeeked?: (time: number) => void;
  className?: string;
};

export const Player = ({ src, className, onPlay, onPause, onEnded, onSeeked }: PlayerProps) => {
  const playerRef = useRef<AudioPlayer>(null);

  return (
    <AudioPlayer
      className={className}
      ref={playerRef}
      showFilledVolume={false}
      showJumpControls={false}
      showSkipControls={false}
      showDownloadProgress={false}
      showFilledProgress={false}
      hasDefaultKeyBindings={false}
      autoPlayAfterSrcChange={false}
      loop={false}
      preload="none"
      src={src}
      onPlay={() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ref: RefObject<AudioPlayer> = (window as any).PLAYING_REF;
        if (ref?.current?.audio.current) {
          ref.current.audio.current.pause();
        }

        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).PLAYING_REF = playerRef;
          onPlay?.();
        }, 0);
      }}
      onEnded={() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).PLAYING_REF = undefined;
        onEnded?.();
      }}
      onPause={() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).PLAYING_REF = undefined;
        onPause?.();
      }}
      onListen={() => {
        if (playerRef?.current?.audio.current) {
          onSeeked?.(playerRef.current.audio.current.currentTime);
        }
      }}
    />
  );
};
