import moment from 'moment';

export const formatSeconds = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  const formattedDuration =
    duration.asHours() >= 1
      ? moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
      : moment.utc(duration.asMilliseconds()).format('mm:ss');
  return formattedDuration;
};
