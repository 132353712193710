'use client';

import classNames from 'classnames';

import { Particles } from '../Particles';
import { SpeechAnimation } from '../SpeechAnimation';
import { Funnels } from '../Funnels/Funnels';

import { useTranslation } from '@/hooks/useTranslation';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { Translation } from '@/components/Translation';

export const Highlights = () => {
  const title = useTranslation(['home', 'title'], 'Transcriptions made simple', {
    nonDefaultLocaleText: 'Simplifying the Transcription Process',
  });
  const pointOne = useTranslation(['home', 'points', 'one'], 'Automatic speech recognition');
  const pointTwo = useTranslation(['home', 'points', 'two'], 'Speech-to-text transcription and translation');
  const pointThree = useTranslation(['home', 'points', 'three'], 'Pre-recorded audio and LiveStream processing');

  return (
    <div className="relative -mt-24 pb-20 pt-20 sm:-mt-40">
      <Particles className="lt-sm:hidden" />
      <a href="#try-it-out">
        <span className="sr-only">
          <Translation name="home.try.title">Try it out yourself</Translation>
        </span>
        <svg
          className={classNames(
            'absolute bottom-0 left-0 right-0 mx-auto translate-y-[50%] cursor-pointer overflow-clip rounded-full bg-white fill-emerald-400 hover:shadow-xl dark:bg-background-800',
            'w-20 p-4 lg:w-28 lg:p-5',
          )}
          viewBox="0 0 511.93 511.93"
        >
          <path d="M476.738,280.436c-4.16-4.16-10.88-4.16-15.04,0l-195.2,195.2V10.996c0-5.333-3.84-10.133-9.067-10.88 c-6.613-0.96-12.267,4.16-12.267,10.56v464.96l-195.093-195.2c-4.267-4.053-10.987-3.947-15.04,0.213 c-3.947,4.16-3.947,10.667,0,14.827L248.365,508.81c4.16,4.16,10.88,4.16,15.04,0l213.333-213.333 C480.898,291.423,480.898,284.596,476.738,280.436z"></path>{' '}
        </svg>
      </a>

      <div className="z-1 relative flex flex-col sm:gap-10">
        <MaxWidthContainer className="flex flex-col items-center text-center lg:gap-10">
          <h1 className="bg-gradient-to-r from-sky-500 to-emerald-500 bg-clip-text p-2 text-xl font-extrabold text-transparent dark:from-sky-400 dark:to-emerald-600 sm:mb-4 sm:mt-20 md:text-4xl lg:text-6xl">
            <SpeechAnimation sequence={[title]} />
          </h1>

          <div className="rounded-xl py-4 sm:p-10">
            <ul className="text-balance flex flex-col gap-4 text-base font-light dark:[text-shadow:_1px_1px_0px_#131326] md:text-2xl">
              <li>{pointOne}</li>
              <li>{pointTwo}</li>
              <li>{pointThree}</li>
            </ul>
          </div>
        </MaxWidthContainer>
        <Funnels />
      </div>
    </div>
  );
};
