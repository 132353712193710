'use client';

import { Timeline } from '@vocalstack/js-sdk';

import { Example } from './ExampleTypes';

import { LocaleKey } from '@/constants/locales';

export const loadTranslation = async (example: Example, language: LocaleKey) => {
  if (example.timeline[0]?.translations?.[language]) {
    return example;
  }
  const response = await fetch(`/api/example?id=${example?.id}&language=${language}`);
  const json = (await response.json()) as { status: string; example: Timeline };

  example.timeline = example.timeline.map((item, index) => ({
    ...item,
    translations: {
      ...item.translations,
      [language]: json.example[index].translations?.[language],
    },
  }));

  return example;
};
