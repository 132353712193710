type GlowingBorderProps = {
  className?: string;
  children: React.ReactNode;
  enabled?: boolean;
};

import classNames from 'classnames';

import './glowingBorder.scss';
import useMediaQuery from '@/hooks/useMediaQuery';
import tailwindConfig from '@/tailwind.config';

export const GlowingBorder = ({ children, enabled, className }: GlowingBorderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const smallMediaQuery = useMediaQuery((tailwindConfig.theme?.extend?.screens as any)?.['lt-sm']?.raw);

  return (
    <div
      className={classNames(
        {
          'glow-border': enabled,
          'glow-border--animated': !smallMediaQuery,
        },
        className,
      )}
      suppressHydrationWarning
    >
      {children}
    </div>
  );
};
